import { Box, Button, Chip, Grid, IconButton, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import OikosLogo from "../../images/logo/OikosDark.svg";
import PlatusLogo from "../../images/logo/PlatusLight.svg";
import logo from "../../images/logo/logo.png";
import { HoverBoxTooltip } from "./HoverBox";
import "./navbar.css";
import { useNavigate } from "react-router-dom";

function NavBar() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  return (
    <Navbar expand="lg" className="py-3">
      <Container>

        <Navbar.Brand href="#" className="me-lg-5">
          <img onClick={() => navigate("/")} className="logo" style={{ height: "100px", aspectRatio: "16/9", objectFit: "cover" }} src={logo} alt="Nife Labs logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" navbarScroll>
            <HoverBoxTooltip title={
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Box sx={{
                    minWidth: "600px", borderRadius: "12px", p: 4,
                    bordeRadius: "16px",
                    boxShadow: "0 4px 30px rgb(0, 0, 0)",
                    backdropFilter: "blur(20px)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    bgcolor: "#121212"
                  }}>
                    <Grid container spacing={2}>
                    
                      <Grid item xs={12} md={3}>
                      <a href="/oikos"> 
                        <img alt="Oikos logo" src={OikosLogo} style={{ height: "50px", objectFit: "cover", textAlign: "center" }} />
                        </a>
                      </Grid>
                      
                      <Grid item xs={12} md={9}>
                        <Typography>Your applications deserve a comfortable abode</Typography>
                        <Typography variant="body2" sx={{ opacity: "0.6", fontSize: "12px", mt: 1 }}>
                          Deploy any applications be it streaming video, games, IOT to a location closer.
                          Give your applications the comfort they need in Hybrid Cloud Computing environment</Typography>
                      </Grid>

                      <Grid item xs={12} md={12} sx={{ borderBottom: "1px solid #5e6379", pb: 2 }}>
                        <Button
                          onClick={() => window.open("https://launch.nife.io/", "_blank")}
                          startIcon={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>}
                          size="small" variant="text" >dashboard</Button>

                        <Button
                          sx={{ ml: 2 }}
                          onClick={() => window.open("https://docs.nife.io/docs/Introduction/Nife/", "_blank")}
                          startIcon={<svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7 18H17V16H7V18Z" fill="currentColor"></path><path d="M17 14H7V12H17V14Z" fill="currentColor"></path><path d="M7 10H11V8H7V10Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z" fill="currentColor"></path></svg>}
                          size="small" variant="text" >Documentation</Button>

                        <Button
                          sx={{ ml: 2 }}
                          onClick={() => window.open("https://launch.nife.io/changelog", "_blank")}
                          startIcon={<svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7 18H17V16H7V18Z" fill="currentColor"></path><path d="M17 14H7V12H17V14Z" fill="currentColor"></path><path d="M7 10H11V8H7V10Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C4.34315 2 3 3.34315 3 5V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V9C21 5.13401 17.866 2 14 2H6ZM6 4H13V9H19V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V5C5 4.44772 5.44772 4 6 4ZM15 4.10002C16.6113 4.4271 17.9413 5.52906 18.584 7H15V4.10002Z" fill="currentColor"></path></svg>}
                          size="small" variant="text" >Changelog</Button>
                      </Grid>

                      

                      <Grid item xs={12} md={3}>
                      <a href="/platus"> 
                        <img alt="Platus logo" src={PlatusLogo} style={{ height: "50px", objectFit: "cover", textAlign: "center" }} />
                        </a>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Typography>Navigating Your Deployment Budgets with Precision.</Typography>
                        <Typography variant="body2" sx={{ opacity: "0.6", fontSize: "12px", mt: 1 }}>
                          Efficiently manage your cloud resources and optimize operational expenses with our Cost Monitoring feature.</Typography>
                      </Grid>
                      

                      <Grid item xs={12} md={12}>
                        <Button
                          onClick={() => window.open("https://landing.nife.io/request-demo", "_blank")}
                          startIcon={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M19 19H5V5h7V3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>}
                          size="small" variant="text" >demo</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

              </Grid>

            }>
              <Typography>Products<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M128 192l128 128 128-128z"></path></svg></Typography>
            </HoverBoxTooltip>

            <HoverBoxTooltip title={
              <Box sx={{
                minWidth: "300px", borderRadius: "12px", p: 2,
                bordeRadius: "16px",
                boxShadow: "0 4px 30px rgb(0, 0, 0)",
                backdropFilter: "blur(20px)",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                bgcolor: "#121212"
              }}>






                <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                  onClick={() => window.location.href = "https://go.doclink.me/view/80B504A5-0570-496D-9FCA-23A727C113E2"} >
                  <ListItemIcon>
                    <IconButton aria-labelledby='Checklist Icon' sx={{ borderRadius: "12px", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M6.99979 7V3C6.99979 2.44772 7.4475 2 7.99979 2H20.9998C21.5521 2 21.9998 2.44772 21.9998 3V16C21.9998 16.5523 21.5521 17 20.9998 17H17V20.9925C17 21.5489 16.551 22 15.9925 22H3.00728C2.45086 22 2 21.5511 2 20.9925L2.00276 8.00748C2.00288 7.45107 2.4518 7 3.01025 7H6.99979ZM8.99979 7H15.9927C16.549 7 17 7.44892 17 8.00748V15H19.9998V4H8.99979V7ZM8.50242 18L14.1593 12.3431L12.7451 10.9289L8.50242 15.1716L6.3811 13.0503L4.96689 14.4645L8.50242 18Z"></path></svg>
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={"Checklist"} secondary="for DevOps"
                  />
                </ListItemButton>



                <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                  onClick={() => window.location.href = "https://go.doclink.me/view/686D4C3C-03AD-4679-A06D-39B193E0550A"} >
                  <ListItemIcon>
                    <IconButton aria-labelledby='Cheat Sheet Icon' sx={{ borderRadius: "12px", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3 5v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2zm7 2h8v2h-8V7zm0 4h8v2h-8v-2zm0 4h8v2h-8v-2zM6 7h2v2H6V7zm0 4h2v2H6v-2zm0 4h2v2H6v-2z"></path></svg>
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={"Cheat Sheet"} secondary="Linux command lines"
                  />
                </ListItemButton>


               
                

                {/* case study */}


                <HoverBoxTooltip
                  title={
                    <Box sx={{
                      minWidth: "300px",
                      borderRadius: "12px",
                      p: 2,
                      boxShadow: "0 4px 30px rgb(0, 0, 0)",
                      backdropFilter: "blur(20px)",
                      border: "1px solid rgba(255, 255, 255, 0.3)",
                      bgcolor: "#121212"
                    }}>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/B00A00FE-3584-4F04-B0A5-260C4BB94721"} >
                        <ListItemText primary={"Smart Retail"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/661A5B4B-C37D-4695-A5DB-CDA322C0D78A"} >
                        <ListItemText primary={"Industrial IOT"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/E5158E91-F4F6-45AE-B6C5-172A501DAD4E"} >
                        <ListItemText primary={"Computer as a Service"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/358636BA-1A22-4AC1-B120-42F407981DBE"} >
                        <ListItemText primary={"Robotics Manufacturing"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/58D7F347-7F34-4984-A7D4-6AF8F60E25A6"} >
                        <ListItemText primary={"Computer Vision: Video Surveillance"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/D3FF106C-DCC3-4ECF-89C5-E0DE327018E2"} >
                        <ListItemText primary={"6G Networks"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/843C65C9-3E1C-40E5-B4A4-6393FD7D0799"} >
                        <ListItemText primary={"Computer Vision"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/FEBD7442-CAA9-4411-BE0E-1E19523776AD"} >
                        <ListItemText primary={"OTT"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/F624C38E-7056-4879-AC39-6241500C6552"} >
                        <ListItemText primary={"Railways"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/1EE0A137-E5E9-4954-8E7C-5AD13F0D44CF"} >
                        <ListItemText primary={"Robotics_manufacturing"} />
                      </ListItemButton >
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/672C6C56-A9B8-4220-B496-F4AE8A4ED6A8"} >
                        <ListItemText primary={"LMS Deployment"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://go.doclink.me/view/030B3B04-D7DF-41D9-9C86-2BACC3528B20"} >
                        <ListItemText primary={"Financial Service"} />
                      </ListItemButton>
                    </Box >
                  }
                  placement="right" // Tooltip appears on the right side
                >
                  <br />
                  <Typography className="px-lg-3">
                    <ListItemIcon>
                      <IconButton aria-labelledby='Case Study Icon' sx={{ borderRadius: "12px", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M428 224H288a48 48 0 0 1-48-48V36a4 4 0 0 0-4-4h-92a64 64 0 0 0-64 64v320a64 64 0 0 0 64 64h224a64 64 0 0 0 64-64V228a4 4 0 0 0-4-4zm-92 160H176a16 16 0 0 1 0-32h160a16 16 0 0 1 0 32zm0-80H176a16 16 0 0 1 0-32h160a16 16 0 0 1 0 32z"></path><path d="M419.22 188.59 275.41 44.78a2 2 0 0 0-3.41 1.41V176a16 16 0 0 0 16 16h129.81a2 2 0 0 0 1.41-3.41z"></path></svg>
                      </IconButton>
                    </ListItemIcon> Case Study
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="m10 17l5-5l-5-5z" /></svg>
                  </Typography>
                </HoverBoxTooltip >
                <br />

                <HoverBoxTooltip
                  title={
                    <Box sx={{
                      minWidth: "300px",
                      borderRadius: "12px",
                      p: 2,
                      boxShadow: "0 4px 30px rgb(0, 0, 0)",
                      backdropFilter: "blur(20px)",
                      border: "1px solid rgba(255, 255, 255, 0.3)",
                      bgcolor: "#121212"
                    }}>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://beacon.by/nife-labs/devops-for-modern-businesses-harness-the-power-of-devops-to-perform-better?utm_source=Newsletter&utm_medium=email&utm_content=Your+Ebook+Download&utm_campaign=Ebook+Email"} >
                        <ListItemText primary={"DevOps for Modern Businesses"} />
                      </ListItemButton>
                      <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                        onClick={() => window.location.href = "https://beacon.by/nife-labs/multi-cloud-technology-for-financial-services"} >
                        <ListItemText primary={"Cloud Technology & its Benefits for Financial Services​"} />
                      </ListItemButton>

                    </Box >
                  }
                  placement="right" // Tooltip appears on the right side
                >
                  <Typography className="px-lg-3">
                    <ListItemIcon>
                      <IconButton aria-labelledby='Case Study Icon' sx={{ borderRadius: "12px", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M428 224H288a48 48 0 0 1-48-48V36a4 4 0 0 0-4-4h-92a64 64 0 0 0-64 64v320a64 64 0 0 0 64 64h224a64 64 0 0 0 64-64V228a4 4 0 0 0-4-4zm-92 160H176a16 16 0 0 1 0-32h160a16 16 0 0 1 0 32zm0-80H176a16 16 0 0 1 0-32h160a16 16 0 0 1 0 32z"></path><path d="M419.22 188.59 275.41 44.78a2 2 0 0 0-3.41 1.41V176a16 16 0 0 0 16 16h129.81a2 2 0 0 0 1.41-3.41z"></path></svg>
                      </IconButton>
                    </ListItemIcon>
                    E Book
                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="m10 17l5-5l-5-5z" /></svg>
                  </Typography>
                </HoverBoxTooltip >










              </Box >
            }>

              <Typography className="px-lg-3">
                Resources<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M128 192l128 128 128-128z"></path></svg>
              </Typography>
            </HoverBoxTooltip >
            <Typography sx={{
              cursor: "pointer", transition: 'opacity 0.3s ease',
              '&:hover': {
                opacity: 0.5,
              }
            }}
              onClick={() => window.location.href = "https://docs.nife.io/marketplace/"} className="px-lg-2">Marketplace</Typography>

            <Typography sx={{
              cursor: "pointer", transition: 'opacity 0.3s ease',
              '&:hover': {
                opacity: 0.5,
              }
            }}
              onClick={() => window.location.href = "https://docs.nife.io/blog/"} className="px-lg-2">Blogs</Typography>

            <HoverBoxTooltip title={
              <Box sx={{
                minWidth: "300px", borderRadius: "12px", p: 2,
                bordeRadius: "16px",
                boxShadow: "0 4px 30px rgb(0, 0, 0)",
                backdropFilter: "blur(20px)",
                border: "1px solid rgba(255, 255, 255, 0.3)",
                bgcolor: "#121212"
              }}>
                <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                  onClick={() => navigate("/about-us")} >
                  <ListItemIcon>
                    <IconButton aria-labelledby='About Us Icon' sx={{ borderRadius: "12px", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                      <svg stroke="currentColor" fill="none" style={{ rotate: "180deg" }} stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 19l7-7 3 3-7 7-3-3z"></path><path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path><path d="M2 2l7.586 7.586"></path><circle cx="11" cy="11" r="2"></circle></svg>
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={"About Us"} secondary="Our Story"
                  />
                </ListItemButton>
                <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                  onClick={() => window.location.href = "/partner"} >
                  <ListItemIcon>
                    <IconButton aria-labelledby='Patners Icon' sx={{ borderRadius: "12px", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke-width="2" viewBox="0 0 24 24" width="1em" height="1em" class="h-5 w-5 stroke-2 group-hover/icon:stroke-blue-500  stroke-neutral-500 undefined"><path d="M9 16A7 7 0 1 0 9 2a7 7 0 0 0 0 14Z"></path><path d="M15 22a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path></svg>
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={"Patners"} secondary="Investors"
                  />
                </ListItemButton>
                <ListItemButton sx={{ px: 1, py: 0.8, borderRadius: "5px" }}
                  onClick={() => window.location.href = "/careers/"} >
                  <ListItemIcon>
                    <IconButton aria-labelledby='Career Icon' sx={{ borderRadius: "12px", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                      <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M4 16l6 -7l5 5l5 -6"></path><path d="M15 14m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path><path d="M10 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path><path d="M4 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path><path d="M20 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path></svg>
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary={"Career"} secondary="future opportunities"
                  />
                </ListItemButton>

              </Box>
            }>
              <Typography className="px-lg-3">
                Company<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M128 192l128 128 128-128z"></path></svg>
              </Typography>
            </HoverBoxTooltip>
            <Typography sx={{
              cursor: "pointer", transition: 'opacity 0.3s ease',
              '&:hover': {
                opacity: 0.5,
              }
            }}
              onClick={() => window.location.href = "https://launch.nife.io/plans/"}

              className="px-lg-2">Pricing</Typography>

          </Nav >
        </Navbar.Collapse >
        <div className="d-flex align-items-center order">
          <span className="line d-lg-inline-block d-none"></span>

          {isMobile ? <Chip onClick={() => window.location.href = "https://launch.nife.io/"} onDelete={() => window.location.href = "https://launch.nife.io/"} deleteIcon={<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M15.5 5H11l5 7-5 7h4.5l5-7z"></path><path d="M8.5 5H4l5 7-5 7h4.5l5-7z"></path></svg>} label="GET STARTED TODAY" clickable /> : <Button
            fullWidth
            // sx={{ml:3}}
            variant="contained"
            onClick={() => window.location.href = "https://launch.nife.io/"}
          >
            Get started
          </Button>}
        </div>
      </Container >
    </Navbar >
  );
}

export default NavBar;
