import { CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import fly from "./images/illustration/fly.gif";
import MainLayout from './layouts/MainLayout';
import HomePage from './pages/HomePage';
import Careers from './pages/Careers';
import Partner from './pages/Partner';
import Platus from './pages/Platus';
import ApplicationPartner from './pages/ApplicationPartner';
import InfraPartners from './pages/InfraPartners';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUsPage from './pages/AboutUsPage'; 
import OikosDetailedPage from './pages/OikosDetailedPage'; 
import ChangeLog from './components/changelog';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: "#0d6efd" },
  },
});


function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Router>
          <MainLayout>
         
              <Routes>
                <Route exact path="/" element={<HomePage />} />
                <Route exact path="/about-us" element={<AboutUsPage />} />
                <Route exact path="/changelog" element={<ChangeLog />} />
                <Route exact path="/careers" element={<Careers />} />
                <Route exact path="/partner" element={<Partner />} />
                <Route exact path="/application-partner" element={<ApplicationPartner />} />
                <Route exact path="/infra-partners" element={<InfraPartners />} />
                <Route exact path="/platus" element={<Platus />} />
                <Route exact path="/oikos" element={<OikosDetailedPage/>} />
                
                
              </Routes>
           
          </MainLayout>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
